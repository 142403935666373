import { reactive, ref } from 'vue';

import { createVueApp } from 'src/utils/vue.js';

import GlobalModal from 'src/components/GlobalModal/GlobalModal.vue';

const showModal = ref(false);

const modalContent = reactive({
  title: '',
  actionLabel: '',
  actionHref: '',
  cancelLabel: '',
  content: '',
});

function close() {
  showModal.value = false;
}

function show(params) {
  Object.keys(modalContent).forEach((key) => {
    modalContent[key] = params[key] ?? null;
  });
  showModal.value = true;
}

const helpers = window.helpers ?? {};

window.helpers = {
  ...helpers,
  globalModal: {
    show,
    close,
  },
};

document.addEventListener('DOMContentLoaded', () => {
  const app = createVueApp({
    render(h) {
      if (!showModal.value) return h('div');

      return h(GlobalModal, {
        props: {
          title: modalContent.title,
          content: modalContent.content,
          actionHref: modalContent.actionHref,
          actionLabel: modalContent.actionLabel,
          cancelLabel: modalContent.cancelLabel,
        },
        on: {
          close,
        },
      });
    },
  });

  app.$mount('[data-global-modal]');
});
