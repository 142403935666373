$(function(){
  // если нет списка вакансий на странице, то ничего делать не нужно
  if($('.cp_cart_list').length === 0){
    return false;
  }

  // плавающая панель внизу
  
  var $window = $(window);
  var period = 20;
  
  function calc_sticky_footer_width(){
    var $sticky_footer_wrapper = $('#sticky_footer_wrapper');
    var $vacancies_list = $('.cp_cart_list table');
    var width = $vacancies_list.width() + 34;
    $sticky_footer_wrapper.css('width', width+'px');
    //console.log('$sticky_footer_wrapper width', width);
  }

  function calc_positions(){
    var $sticky_footer = $('.sticky_footer');
    var $sticky_footer_wrapper = $('#sticky_footer_wrapper');
    var $sticky_footer_placeholder = $('#sticky_footer_placeholder');
    var $vacancies_list = $('.cp_cart_list table');

    calc_sticky_footer_width();
    //var $sticky_footer = $('.sticky_footer');
    var vacancies_list_bottom = $vacancies_list.outerHeight() + $vacancies_list.offset().top + 100;
    var window_bottom = $window.scrollTop() + $window.height();
    //console.log(11);
    if( vacancies_list_bottom > window_bottom && !$sticky_footer.hasClass('hidden') ){
      $sticky_footer_wrapper.addClass('fixed');
      $sticky_footer_placeholder.removeClass('hidden');
    }else{
      $sticky_footer_wrapper.removeClass('fixed');
      $sticky_footer_placeholder.addClass('hidden');
    }
    //console.log('vacancies_list_bottom: ', vacancies_list_bottom, 'window_bottom:', window_bottom );
    
    setTimeout(calc_positions, period);
  }

  setTimeout(calc_positions, period);
  
});




