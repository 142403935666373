export const bem = (baseClass, mods) => {
  const keys = Object.keys(mods);
  const res = [];
  keys.forEach(key => {
    const param = mods[key];
    if (!param) return;

    if (Array.isArray(param)) {
      param.forEach(item => {
        res.push(`${baseClass}--${key}-${item}`);
      });
    } else {
      const mod = typeof param === 'boolean' ? key : `${key}-${param}`;
      res.push(`${baseClass}--${mod}`);
    }
  });
  return res;
};