export function captureException(error, options = {}) {
  const { Sentry } = window;
  if (!Sentry) throw error;

  Sentry.withScope(scope => {
    if (options.label) {
      scope.setTag('label', options.label);
    }

    if (options.extra) {
      scope.setExtras(options.extra);
    }

    Sentry.captureException(error);
  });
}
