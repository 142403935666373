export function debounce(fn, wait) {
  let timeout;
  const debounced = function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      fn.apply(this, args);
    }, wait);
  };
  debounced.cancel = () => clearTimeout(timeout);
  return debounced;
}