<template>
  <ModalWindow
    :title="title"
    :has-header="!!title"
    @close="$emit('close')"
    appearance="new"
  >
    <template v-if="content" #body>
      <div class="global-window__content">
        {{ content }}
      </div>
    </template>
    <template #footer>
      <div class="global-window__footer">
        <BasicButton
          v-if="actionLabel && actionHref"
          :href="actionHref"
          appearance="primary"
          size="l"
          rel="nofollow"
        >
          {{ actionLabel }}
        </BasicButton>
        <BasicButton
          v-if="cancelLabel"
          appearance="secondary"
          size="l"
          @click="$emit('close')"
        >
          {{ cancelLabel }}
        </BasicButton>
      </div>
    </template>
  </ModalWindow>
</template>

<script>
import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';

export default {
  name: 'GlobalModal',
  components: {
    BasicButton,
    ModalWindow,
  },
  props: {
    title: {
      type: String,
    },
    actionLabel: {
      type: String,
    },
    actionHref: {
      type: String,
    },
    cancelLabel: {
      type: String,
    },
    content: {
      type: String,
    },
  },
  emits: ['close'],
};
</script>
