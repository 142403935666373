import Vue from 'vue';
import { MediaQueryProvider } from 'vue-component-media-queries';

export function createVueApp({ el, ...options }) {
  const root = {
    render(h) {
      return h(MediaQueryProvider, {
        props: {
          queries: {
            mobile: '(max-width: 1023px)',
          }
        }
      }, [h(options)]);
    },
  };
  if (el) {
    return new Vue(root).$mount(el);
  }
  return new Vue(root);
}

export const createPropModel = (name) => {
  const propName = name !== undefined ? name : 'value';
  const eventName = name !== undefined ? `update:${name}` : 'input';
  return {
    get() {
      return this[propName];
    },
    set(value) {
      this.$emit(eventName, value);
    },
  };
};

export const modelValueConfig = () => {
  return {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
}

export const createKeyGetter = (key = 'id') => item => typeof item === 'object' ? item[key] : item;