<template>
  <component
    :is="href ? 'a' : 'button'"
    :href="href"
    :type="href ? null : type"
    class="button-comp"
    :class="bem('button-comp', { appearance, size, fullWidth })"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  export default {
    name: 'BasicButton',
    props: {
      href: String,
      type: {
        type: String,
        default: 'button'
      },
      appearance: String,
      size: String,
      fullWidth: Boolean,
    },
    methods: {
      bem,
    },
  }
</script>