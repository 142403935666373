import Toastify from 'toastify-js';

import 'toastify-js/src/toastify.css';

const colors = {
  error: '#f15a5c',
  alert: '#1c977f',
  notice: '#1abc9c',
  warning: '#fcb541',
};

export function notify({ message, type = 'notice', delay = 3 }) {
  Toastify({
    text: message,
    escapeMarkup: false,
    duration: delay * 1000,
    close: true,
    gravity: 'top',
    position: 'right',
    backgroundColor: colors[type],
    stopOnFocus: true,
  }).showToast();
}