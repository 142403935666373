<template>
  <span class="text-input" :class="bem('text-input', { appearance, invalid, disabled, withIcon })">
    <input
      ref="input"
      class="text-input__input"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :autocomplete="autocomplete"
      @input="fixInput"
      v-model="model"
      v-bind="$attrs"
      v-on="filteredListeners"
    >
    <span v-if="withIcon" class="text-input__icon">
      <slot name="icon" />
    </span>
  </span>
</template>

<script>

  import { bem } from 'src/utils/bem.js';
  import { createPropModel, modelValueConfig } from 'src/utils/vue.js';

  export default {
    name: 'TextInput',
    model: modelValueConfig(),
    inheritAttrs: false,
    props: {
      modelValue: String,
      type: String,
      appearance: String,
      placeholder: String,
      invalid: Boolean,
      disabled: Boolean,
      autocomplete: String,
    },
    methods: {
      bem,
      fixInput(event) {
        // На адроиде с какой-то версии Хром начал включать режим композиции (compositionstart) при любом вводе текста
        // Из-за этого не выставляется значение у модели на событии input, только на change
        // (Vue предотвращает лишнее срабатывание когда выставлен флаг композиции)
        // Чтобы починить будем перезаписывать свойство которое для этого устанавливает Vue
        // Подробнее в https://github.com/vuejs/vue/pull/9814
        // eslint-disable-next-line no-param-reassign
        event.target.composing = false;
      },
      focus() {
        this.$refs.input.focus();
      }
    },
    computed: {
      model: createPropModel('modelValue'),
      filteredListeners() {
        const { 'update:modelValue':_, input, ...rest } = this.$listeners;
        return rest;
      },
      withIcon() {
        return !!this.$slots.icon;
      }
    },
  }
</script>