import { setMaxNested } from 'src/global-helpers.js';

$(function () {
  // прерываем выполнение скрипта, если нет нужной формы на странице
  if (!$('.js-contacts-form').length) return;

  /**
   * Для nested полей нужно навесить обработчики на селекты и инпуты типа location
   */
  $(document).on('nested:fieldAdded', function (event) {
    const { field } = event;

    // window.helpers.customize_select(field.find('select'));
    $(field).find('input[type="text"]').focus();
  });

  setMaxNested(2, $('#add_phone'), $('#ihave_phones'));
  setMaxNested(2, $('#add_email'), $('#ihave_emails'));
});
