export function fadeOut(el){
  // eslint-disable-next-line no-param-reassign
  el.style.opacity = 1;

  (function fade() {
    // eslint-disable-next-line no-cond-assign,no-param-reassign
    if ((el.style.opacity -= .1) < 0) {
      // eslint-disable-next-line no-param-reassign
      el.style.display = 'none';
    } else {
      requestAnimationFrame(fade);
    }
  })();
}
